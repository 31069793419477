<template>
  <div class="p-grid">
    <div class="p-col-12">
      <h3>
        <i class="pi pi-file" style="font-size: 1.6rem"></i> CAP DIGITAL -
        Pedido de Título
      </h3>
      <p style="color: red">
        Todas as informações prestadas a seguir são de responsabilidade da
        imobiliária e de suma importância para a correta contratação do título
        para garantia do contrato. O formulário a seguir é um pedido de emissão
        do título. O título só terá real validade após a emissão do mesmo e
        quitação do boleto confirmada pela seguradora.
      </p>

      <div class="card p-fluid p-mt-4">
        <h5>
          <i
            class="pi pi-home"
            style="font-size: 1.2rem; margin-right: 6px"
          ></i>
          Imóvel que está sendo alugado:
        </h5>
        <div class="p-grid">
          <div class="p-col-12 p-field">
            <div class="p-inputgroup">
              <AutoComplete
                id="imovel"
                v-model="imovel"
                :suggestions="imovelResultados"
                @complete="filtrarAutoCompleteImovel($event)"
                @blur="validaAutoComplete('imovel')"
                field="nome"
                placeholder="Informe o código ou nome do imóvel"
                :class="
                  fieldsError.some((v) => v === 'imovel') ? 'p-invalid' : ''
                "
              />
              <Button
                label="cadastrar"
                class="p-button-warning"
                @click="dialogImovel = true"
              />
            </div>
          </div>

          <div
            class="p-col-12 p-field selecao-detalhes"
            v-if="typeof imovel == 'object' && imovel != null"
          >
            <div class="p-grid">
              <div class="p-field p-md-2">
                <label for="id_proprio">Identificação:</label>
                <span>{{ imovel.id_proprio }}</span>
              </div>
              <div class="p-field p-md-10">
                <label for="nome">Nome:</label>
                <span>{{ imovel.nome }}</span>
              </div>
            </div>

            <!-- Endereço -->
            <div class="p-grid">
              <div class="p-field p-md-2">
                <label for="cep">CEP:</label>
                <span>{{ imovel.cep }}</span>
              </div>

              <div class="p-field p-md-5">
                <label for="logradouro">Endereço:</label>
                <span>{{ imovel.logradouro }}</span>
              </div>

              <div class="p-field p-md-1">
                <label for="numero">Número:</label>
                <span>{{ imovel.numero }}</span>
              </div>

              <div class="p-field p-md-4">
                <label for="complemento">Complemento:</label>
                <span>{{ imovel.complemento }}</span>
              </div>

              <div class="p-field p-md-4">
                <label for="bairro">Bairro:</label>
                <span>{{ imovel.bairro }}</span>
              </div>

              <div class="p-field p-md-4">
                <label for="cidade">Cidade:</label>
                <span>{{ imovel.cidade }}</span>
              </div>

              <div class="p-field p-md-1">
                <label for="uf">UF</label>
                <span>{{ imovel.uf }}</span>
              </div>
            </div>
          </div>
        </div>

        <h5 v-if="typeof imovel == 'object' && imovel != null">
          <i
            class="pi pi-user"
            style="font-size: 1.2rem; margin-right: 6px"
          ></i>
          Locador / Proprietário:
        </h5>
        <div class="p-grid" v-if="typeof imovel == 'object' && imovel != null">
          <div class="p-col-12 p-field p-mb-1">
            <SelectButton
              id="locatario_tipo"
              v-model="locador_tipo"
              :class="
                fieldsError.some((v) => v === 'locador_tipo') ? 'p-invalid' : ''
              "
              :options="[
                { id: 'PF', nome: 'Pessoa Física' },
                { id: 'PJ', nome: 'Pessoa Jurídica' },
              ]"
              optionLabel="nome"
              optionValue="id"
              style="width: 300px"
            />
          </div>

          <div class="p-col-12 p-field" v-if="locador_tipo != ''">
            <div class="p-inputgroup">
              <AutoComplete
                id="locador"
                v-model="locador"
                :suggestions="pessoaResultados"
                @complete="filtrarAutoCompletePessoa($event, locador_tipo)"
                @blur="validaAutoComplete('locador')"
                field="nome"
                placeholder="Informe o nome da pessoa"
                :class="
                  fieldsError.some((v) => v === 'locador') ? 'p-invalid' : ''
                "
              />
              <Button
                label="cadastrar"
                class="p-button-warning"
                @click="
                  pessoa_retorno = 'locador';
                  pessoa_tipo = locador_tipo;
                  dialogPessoa = true;
                "
              />
            </div>
          </div>

          <div
            class="p-col-12 p-field selecao-detalhes"
            v-if="
              typeof locador == 'object' &&
              locador != null &&
              typeof locador.cpfcnpj != 'undefined'
            "
          >
            <Button
              label="atualizar dados do locador"
              icon="pi pi-pencil"
              @click="
                pessoa_retorno = 'locador';
                pessoa_tipo = locador_tipo;
                pessoa_edit = locador;
              "
              class="p-mb-2 p-button-secondary"
              style="width: auto"
            />

            <div class="p-grid">
              <div class="p-field p-md-3" v-if="locador_tipo == 'PF'">
                <label for="cpf">CPF:</label>
                <span>{{ locador.cpfcnpj }}</span>
              </div>

              <div class="p-field p-md-3" v-else>
                <label for="cpf">CNPJ:</label>
                <span>{{ locador.cpfcnpj }}</span>
              </div>

              <div class="p-field p-md-9">
                <label for="nome">
                  <span v-if="locador_tipo == 'PF'">Nome Completo</span>
                  <span v-else>Razão Social:</span>
                </label>
                <span>{{ locador.nome }}</span>
              </div>

              <div class="p-field p-md-3" v-if="locador_tipo == 'PF'">
                <label for="rg">RG:</label>
                <span>{{ locador.rg }}</span>
              </div>

              <div class="p-field p-md-3" v-if="locador_tipo == 'PF'">
                <label for="rg_orgao">Órgão Emissor</label>
                <span>{{ locador.rg_orgao }}</span>
              </div>

              <div class="p-field p-md-3" v-if="locador_tipo == 'PF'">
                <label for="rg_expedicao">Expedição</label>
                <span>{{ locador.rg_expedicao }}</span>
              </div>

              <div class="p-field p-md-3">
                <label for="nascimento">
                  <span v-if="locador_tipo == 'PF'">Nascimento</span>
                  <span v-else>Fundação</span>
                </label>
                <span>{{ $utils.formatDateToBr(locador.nascimento) }}</span>
              </div>

              <div
                :class="
                  locador_tipo == 'PF' ? 'p-field p-col-12' : 'p-field p-col-9'
                "
              >
                <label for="email">E-Mail:</label>
                <span>{{ locador.email }}</span>
              </div>

              <div class="p-field p-md-6" v-if="locador_tipo == 'PF'">
                <label for="estado_civil">Estado Civil:</label>
                <span>{{ locador.estado_civil_item.nome }}</span>
              </div>

              <div class="p-field p-md-6" v-if="locador_tipo == 'PF'">
                <label for="sexo">Sexo:</label>
                <span>{{ locador.sexo }}</span>
              </div>
            </div>

            <div class="p-grid">
              <div class="p-md-12" v-if="locador_tipo == 'PJ'">
                <h6 class="p-mb-0">
                  <i class="pi pi-map-marker"></i> Endereço da Empresa
                </h6>
              </div>

              <div class="p-field p-md-2">
                <label for="residencia_cep">CEP:</label>
                <span>{{ locador.cep }}</span>
              </div>

              <div class="p-field p-md-8">
                <label for="residencia_logradouro">Endereço:</label>
                <span>{{ locador.logradouro }}</span>
              </div>

              <div class="p-field p-md-2">
                <label for="residencia_numero">Número:</label>
                <span>{{ locador.numero }}</span>
              </div>

              <div class="p-field p-md-12">
                <label for="residencia_complemento">Complemento:</label>
                <span>{{ locador.complemento }}</span>
              </div>

              <div class="p-field p-md-5">
                <label for="residencia_bairro">Bairro</label>
                <span>{{ locador.bairro }}</span>
              </div>

              <div class="p-field p-md-5">
                <label for="residencia_cidade">Cidade</label>
                <span>{{ locador.cidade }}</span>
              </div>

              <div class="p-field p-md-2">
                <label for="residencia_uf">UF</label>
                <span>{{ locador.uf }}</span>
              </div>
            </div>

            <div class="p-grid" v-if="locador.contatos.length">
              <div class="p-col-12">
                <h6 class="p-mb-0"><i class="pi pi-phone"></i> Telefones</h6>
              </div>

              <div class="p-col-12 p-field">
                <DataTable sortMode="single" :value="locador.contatos">
                  <Column
                    field="nome"
                    headerClass="colNome"
                    header="Observações"
                  >
                    <template #body="slotProps">
                      {{
                        slotProps.data.nome ? slotProps.data.nome : "Nenhuma"
                      }}
                    </template>
                  </Column>
                  <Column
                    field="telefone"
                    headerClass="colTelefone"
                    headerStyle="width:145px"
                    header="Telefone"
                  >
                    <template #body="slotProps">
                      {{ $utils.formatPhone(slotProps.data.telefone) }}
                    </template>
                  </Column>
                  <Column
                    field="tipo"
                    headerClass="colTipo"
                    headerStyle="width:67px"
                    header="Tipo"
                  ></Column>
                  <Column
                    field="e_whatsapp"
                    headerClass="colWhatsapp"
                    headerStyle="width:65px"
                    header="Whatsapp?"
                  >
                    <template #body="slotProps">
                      {{ slotProps.data.e_whatsapp ? "Sim" : "Não" }}
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>

            <div class="p-grid" v-if="locador_tipo == 'PJ'">
              <div class="p-col-12">
                <h6 class="p-mb-0">
                  <i class="pi pi-users"></i> Sócios Representantes
                </h6>
              </div>

              <div class="p-col-12 p-field" v-if="locador.socios.length > 0">
                <DataTable sortMode="single" :value="locador.socios">
                  <Column
                    field="socio_item.cpfcnpj"
                    headerClass="colCPF"
                    header="CPF"
                  ></Column>
                  <Column
                    field="socio_item.nome"
                    headerClass="colNome"
                    header="Nome"
                  ></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>

        <h5 v-if="typeof locador == 'object' && locador != null">
          <i
            class="pi pi-user"
            style="font-size: 1.2rem; margin-right: 6px"
          ></i>
          Locatário (Titular):
        </h5>
        <div
          class="p-grid"
          v-if="typeof locador == 'object' && locador != null"
        >
          <div class="p-col-12 p-field p-mb-1">
            <SelectButton
              id="locatario_tipo"
              v-model="locatario_tipo"
              :class="
                fieldsError.some((v) => v === 'locatario_tipo')
                  ? 'p-invalid'
                  : ''
              "
              :options="[
                { id: 'PF', nome: 'Pessoa Física' },
                { id: 'PJ', nome: 'Pessoa Jurídica' },
              ]"
              optionLabel="nome"
              optionValue="id"
              style="width: 300px"
            />
          </div>

          <div class="p-col-12 p-field" v-if="locatario_tipo != ''">
            <div class="p-inputgroup">
              <AutoComplete
                id="locatario"
                v-model="locatario"
                :suggestions="pessoaResultados"
                @complete="filtrarAutoCompletePessoa($event, locatario_tipo)"
                @blur="validaAutoComplete('locatario')"
                field="nome"
                placeholder="Informe o nome da pessoa"
                :class="
                  fieldsError.some((v) => v === 'locatario') ? 'p-invalid' : ''
                "
              />
              <Button
                label="cadastrar"
                class="p-button-warning"
                @click="
                  pessoa_retorno = 'locatario';
                  pessoa_tipo = locatario_tipo;
                  dialogPessoa = true;
                "
              />
            </div>
          </div>

          <div
            class="p-col-12 p-field selecao-detalhes"
            v-if="
              typeof locatario == 'object' &&
              locatario != null &&
              typeof locatario.cpfcnpj != 'undefined'
            "
          >
            <Button
              label="atualizar dados do locatário"
              icon="pi pi-pencil"
              @click="
                pessoa_retorno = 'locatario';
                pessoa_tipo = locatario_tipo;
                pessoa_edit = locatario;
              "
              class="p-mb-2 p-button-secondary"
              style="width: auto"
            />

            <div class="p-grid">
              <div class="p-field p-md-3" v-if="locatario_tipo == 'PF'">
                <label for="cpf">CPF:</label>
                <span>{{ locatario.cpfcnpj }}</span>
              </div>

              <div class="p-field p-md-3" v-else>
                <label for="cpf">CNPJ:</label>
                <span>{{ locatario.cpfcnpj }}</span>
              </div>

              <div class="p-field p-md-9">
                <label for="nome">
                  <span v-if="locatario_tipo == 'PF'">Nome Completo</span>
                  <span v-else>Razão Social:</span>
                </label>
                <span>{{ locatario.nome }}</span>
              </div>

              <div class="p-field p-md-3" v-if="locatario_tipo == 'PF'">
                <label for="rg">RG:</label>
                <span>{{ locatario.rg }}</span>
              </div>

              <div class="p-field p-md-3" v-if="locatario_tipo == 'PF'">
                <label for="rg_orgao">Órgão Emissor</label>
                <span>{{ locatario.rg_orgao }}</span>
              </div>

              <div class="p-field p-md-3" v-if="locatario_tipo == 'PF'">
                <label for="rg_expedicao">Expedição</label>
                <span>{{ $utils.formatDateToBr(locatario.rg_expedicao) }}</span>
              </div>

              <div class="p-field p-md-3">
                <label for="nascimento">
                  <span v-if="locatario_tipo == 'PF'">Nascimento</span>
                  <span v-else>Fundação</span>
                </label>
                <span>{{ $utils.formatDateToBr(locatario.nascimento) }}</span>
              </div>

              <div
                :class="
                  locatario_tipo == 'PF'
                    ? 'p-field p-col-12'
                    : 'p-field p-col-9'
                "
              >
                <label for="email">E-Mail:</label>
                <span>{{ locatario.email }}</span>
              </div>

              <div class="p-field p-md-6" v-if="locatario_tipo == 'PF'">
                <label for="estado_civil">Estado Civil:</label>
                <span>{{ locatario.estado_civil_item.nome }}</span>
              </div>

              <div class="p-field p-md-6" v-if="locatario_tipo == 'PF'">
                <label for="sexo">Sexo:</label>
                <span>{{ locatario.sexo }}</span>
              </div>
            </div>

            <div class="p-grid">
              <div class="p-md-12" v-if="locatario_tipo == 'PJ'">
                <h6 class="p-mb-0">
                  <i class="pi pi-map-marker"></i> Endereço da Empresa
                </h6>
              </div>

              <div class="p-field p-md-2">
                <label for="residencia_cep">CEP:</label>
                <span>{{ locatario.cep }}</span>
              </div>

              <div class="p-field p-md-8">
                <label for="residencia_logradouro">Endereço:</label>
                <span>{{ locatario.logradouro }}</span>
              </div>

              <div class="p-field p-md-2">
                <label for="residencia_numero">Número:</label>
                <span>{{ locatario.numero }}</span>
              </div>

              <div class="p-field p-md-12">
                <label for="residencia_complemento">Complemento:</label>
                <span>{{ locatario.complemento }}</span>
              </div>

              <div class="p-field p-md-5">
                <label for="residencia_bairro">Bairro</label>
                <span>{{ locatario.bairro }}</span>
              </div>

              <div class="p-field p-md-5">
                <label for="residencia_cidade">Cidade</label>
                <span>{{ locatario.cidade }}</span>
              </div>

              <div class="p-field p-md-2">
                <label for="residencia_uf">UF</label>
                <span>{{ locatario.uf }}</span>
              </div>
            </div>

            <div class="p-grid" v-if="locatario.contatos.length">
              <div class="p-col-12">
                <h6 class="p-mb-0"><i class="pi pi-phone"></i> Telefones</h6>
              </div>

              <div class="p-col-12 p-field">
                <DataTable sortMode="single" :value="locatario.contatos">
                  <Column
                    field="nome"
                    headerClass="colNome"
                    header="Observações"
                  >
                    <template #body="slotProps">
                      {{
                        slotProps.data.nome ? slotProps.data.nome : "Nenhuma"
                      }}
                    </template>
                  </Column>
                  <Column
                    field="telefone"
                    headerClass="colTelefone"
                    headerStyle="width:145px"
                    header="Telefone"
                  >
                    <template #body="slotProps">
                      {{ $utils.formatPhone(slotProps.data.telefone) }}
                    </template>
                  </Column>
                  <Column
                    field="tipo"
                    headerClass="colTipo"
                    headerStyle="width:67px"
                    header="Tipo"
                  ></Column>
                  <Column
                    field="e_whatsapp"
                    headerClass="colWhatsapp"
                    headerStyle="width:65px"
                    header="Whatsapp?"
                  >
                    <template #body="slotProps">
                      {{ slotProps.data.e_whatsapp ? "Sim" : "Não" }}
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>

            <div class="p-grid" v-if="locatario_tipo == 'PJ'">
              <div class="p-col-12">
                <h6 class="p-mb-0">
                  <i class="pi pi-users"></i> Sócios Representantes
                </h6>
              </div>

              <div class="p-col-12 p-field" v-if="locatario.socios.length > 0">
                <DataTable sortMode="single" :value="locatario.socios">
                  <Column
                    field="socio_item.cpfcnpj"
                    headerClass="colCPF"
                    header="CPF"
                  ></Column>
                  <Column
                    field="socio_item.nome"
                    headerClass="colNome"
                    header="Nome"
                  ></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>

        <h5 v-if="typeof locatario == 'object' && locatario != null">
          <i
            class="pi pi-dollar"
            style="font-size: 1.2rem; margin-right: 6px"
          ></i>
          Valor do Título:
        </h5>
        <div
          class="p-grid"
          v-if="typeof locatario == 'object' && locatario != null"
        >
          <div class="p-col-12 p-field">
            <InputNumber
              id="valor"
              mode="currency"
              currency="BRL"
              locale="pt-BR"
              v-model="valor"
              :class="fieldsError.some((v) => v === 'valor') ? 'p-invalid' : ''"
              style="width: 300px"
              @focus="$utils.inputNumberFocus"
            />
          </div>
        </div>

        <h5 v-if="valor > 0">
          <i
            class="pi pi-globe"
            style="font-size: 1.2rem; margin-right: 6px"
          ></i>
          Seguradora:
        </h5>
        <div class="p-grid" v-if="valor > 0">
          <div
            :class="
              'p-col-' +
              12 / seguradoras.length +
              ' p-field p-mb-1 p-text-center'
            "
            v-for="s of seguradoras"
            :key="s.id"
          >
            <Button
              type="button"
              @click="seguradora = s"
              :class="
                typeof seguradora == 'object' &&
                seguradora != null &&
                seguradora.id == s.id
                  ? 'button-seguradora selecionada'
                  : 'button-seguradora'
              "
            >
              <img
                :src="'/assets/layout/images/' + s.logo"
                :alt="s.nome"
                style="max-width: 100%"
              />
            </Button>
          </div>
        </div>

        <h5 v-if="typeof seguradora == 'object' && seguradora != null">
          <i
            class="pi pi-list"
            style="font-size: 1.2rem; margin-right: 6px"
          ></i>
          Plano:
        </h5>
        <div
          class="p-grid"
          v-if="typeof seguradora == 'object' && seguradora != null"
        >
          <div class="p-col-12 p-field p-mb-5">
            <SelectButton
              id="plano"
              v-model="plano"
              :class="fieldsError.some((v) => v === 'plano') ? 'p-invalid' : ''"
              :options="planos"
              optionLabel="nome"
              style="width: 700px; max-width: 100%"
            />
          </div>
        </div>

        <div class="p-grid" v-if="typeof plano == 'object' && plano != null">
          <div class="p-col-12 p-field p-mb-1">
            <Button
              label="Enviar"
              icon="pi pi-check"
              class="p-button-lg p-button-success"
              style="width: auto"
              @click="criar_alterar"
              v-if="$auth.userCan('CAPITALIZACAO_CADASTRAR')"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Dialog
    header="Imobiliária"
    :closable="false"
    :modal="true"
    v-model:visible="dialogImobiliaria"
  >
    <div class="p-field">
      <label for="imobiliaria" class="p-d-block"
        >Selecione a Imobiliária responsável por esse cadastro:</label
      >
      <Dropdown
        id="imobiliaria"
        v-model="imobiliaria"
        :options="imobiliarias"
        :showClear="true"
        optionLabel="nome"
        optionValue="id"
        placeholder="Selecione"
        :filter="true"
        filterPlaceholder="Buscar Imobiliária"
        style="width: 300px"
      />
    </div>
  </Dialog>

  <Dialog
    v-model:visible="dialogImovel"
    style="max-width: 98%; width: 1000px"
    :modal="true"
  >
    <ImoveisEdit @save="imovelCadastrado" />
  </Dialog>

  <Dialog v-model:visible="dialogPessoa" style="width: 98%" :modal="true">
    <PessoasSimplificadoEdit
      :imobiliaria="imobiliaria"
      :tipo="pessoa_tipo"
      :pessoa="pessoa_edit"
      @created="pessoaCadastrada"
      @updated="pessoaAtualizada"
    />
  </Dialog>

  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />
</template>

<script>

import PessoasSimplificadoEdit from "../pessoas/PessoasSimplificadoEdit.vue";
import ImoveisEdit from "../imoveis/ImoveisEdit.vue";

export default {
  data() {
    return {
      loading: 0,

      fieldsError: [],

      dialogImobiliaria: false,
      dialogImovel: false,
      dialogPessoa: false,

      imobiliarias: [],

      imovelResultados: [],

      pessoa_tipo: null,
      pessoa_edit: null,
      pessoa_retorno: "",

      pessoaSelecionada: null,
      pessoaResultados: [],

      seguradoras: [
        {
          id: 9,
          nome: "ICATU",
          logo: "logo-icatu-seguros.png",
          planos: [
            {
              id: 12,
              nome: "12 Meses",
            },
            {
              id: 15,
              nome: "15 Meses",
            },
          ],
        },
        {
          id: 10,
          nome: "BRASILCAP (Banco do Brasil)",
          logo: "logo-brasilcap.png",
          planos: [
            {
              id: 12,
              nome: "12 Meses",
            },
            {
              id: 15,
              nome: "15 Meses",
            },
            {
              id: 30,
              nome: "30 Meses",
            },
          ],
        },
        {
          id: 4,
          nome: "PORTO SEGURO",
          logo: "logo-porto-seguro.png",
          planos: [
            {
              id: 18,
              nome: "18 Meses",
            },
            {
              id: 24,
              nome: "24 Meses",
            },
            {
              id: 30,
              nome: "30 Meses",
            },
          ],
        },
      ],

      planos: [],

      // Formulário

      imobiliaria: null,
      imovel: null,
      locatario_tipo: "",
      locatario: null,
      locador_tipo: "",
      locador: null,
      valor: 0,
      seguradora: null,
      plano: null,
    };
  },
  mounted() {
    const self = this;

    if (this.$auth.currentUser.imobiliaria == null) {
      this.dialogImobiliaria = true;
      this.loading++;

      this.$api.get("/imobiliarias/?fields=nome").then(function (response) {
        const dados = response.data;
        if (dados.success) {
          self.imobiliarias = dados.data;
        }

        self.loading--;
      });
    } else {
      this.imobiliaria = this.$auth.currentUser.imobiliaria;
    }
  },
  watch: {
    imobiliaria(val) {
      if (typeof val == "number") {
        this.dialogImobiliaria = false;
      }
    },
    imovel(val) {
      if (typeof val == "object" && val != null) {
        if (val.locador_item != null) {
          this.locador_tipo = val.locador_item.tipo;
          this.carregarDadosPessoa("locador", val.locador_item.id);
        } else {
          this.locador_tipo = "";
          this.locador = null;
        }
      } else {
        this.locador_tipo = "";
        this.locador = null;
      }
    },
    locador(val) {
      if (
        typeof val == "object" &&
        val != null &&
        typeof val.cpfcnpj == "undefined"
      ) {
        this.carregarDadosPessoa("locador", val.id);
      }
    },
    locatario(val) {
      if (
        typeof val == "object" &&
        val != null &&
        typeof val.cpfcnpj == "undefined"
      ) {
        this.carregarDadosPessoa("locatario", val.id);
      }
    },
    pessoa_edit(val) {
      if (typeof val == "object" && val != null) {
        this.dialogPessoa = true;
      } else {
        this.dialogPessoa = false;
      }
    },
    seguradora(val) {
      if (typeof val == "object" && val != null) {
        this.planos = this.$utils.getStdObject(val.planos);
      }
    },
    locador_tipo() {
      this.locador = null;
    },
  },
  methods: {
    carregarDadosPessoa(campo, id) {
      const self = this;

      this.$api.get("/pessoas/" + id).then(function (response) {
        const dados = response.data;
        if (dados.success) {
          self[campo] = dados.data;
        }
      });
    },

    imovelCadastrado(imovel) {
      if (!imovel.nome)
        imovel.nome =
          imovel.logradouro +
          ", " +
          imovel.bairro +
          ", " +
          imovel.cidade +
          ", " +
          imovel.uf;

      if (imovel.id_proprio)
        imovel.nome = imovel.id_proprio + " - " + imovel.nome;

      this.imovelResultados = [imovel];
      this.imovel = imovel;

      this.dialogImovel = false;
    },

    validaAutoComplete(val) {
      if (typeof this[val] != "object") this[val] = null;
    },

    filtrarAutoCompleteImovel: function (event) {
      const self = this;

      const q = event.query.toLowerCase();

      this.$api
        .get(
          "/imoveis?relationships=locador_item:id,nome,tipo&filters=" +
            '{"str":["' +
            q +
            '"],"status":["ATIVO"],"imobiliaria":["' +
            this.imobiliaria +
            '"]}'
        )
        .then(function (response) {
          let dados = response.data;
          if (dados.success) {
            for (let i = 0; i < dados.data.length; i++) {

              let identificacao = [];

              if(typeof dados.data[i].id_proprio == "string" && dados.data[i].id_proprio != "")
                identificacao.push(dados.data[i].id_proprio);

              if(typeof dados.data[i].nome == "string" && dados.data[i].nome != "")
                identificacao.push(dados.data[i].nome);

              identificacao = (identificacao.length > 0)?identificacao.join(" - "):"";

              let endereco = [
                dados.data[i].logradouro,
                dados.data[i].numero,
                dados.data[i].complemento,
                dados.data[i].bairro,
                dados.data[i].cidade,
                dados.data[i].uf,
              ].filter(Boolean).join(", ");

              dados.data[i].nome = (identificacao != "")?identificacao + " - ":"";
              
              dados.data[i].nome += endereco;


            }

            self.imovelResultados = dados.data;
          }
        });
    },

    filtrarAutoCompletePessoa: function (event, tipo) {
      const self = this;

      const q = event.query.toLowerCase();

      this.$api
        .get(
          "/pessoas?relationships=socios;contatos;estado_civil_item&filters=" +
            '{"str":["' +
            q +
            '"],"tipo":["' +
            tipo +
            '"],"imobiliaria":["' +
            this.imobiliaria +
            '"]}'
        )
        .then(function (response) {
          const dados = response.data;
          if (dados.success) {
            self.pessoaResultados = dados.data;
            for(const r of self.pessoaResultados)
              r.nome += " - " + self.$utils.formatCpfCnpj(r.cpfcnpj);
          }
        });
    },

    pessoaCadastrada(pessoa) {
      if (typeof pessoa == "object" && pessoa != null) {
        this.pessoaResultados = [pessoa];
        this[this.pessoa_retorno] = pessoa;
      }

      this.dialogPessoa = false;
    },

    pessoaAtualizada(pessoa) {
      if (typeof pessoa == "object" && pessoa != null) {
        this[this.pessoa_retorno] = pessoa;
      }

      this.pessoa_edit = null;
    },

    criar_alterar() {
      const self = this;
      const criar = true;

      this.errorObject = [];

      let dados = {
        imovel: this.$utils.valOrNull(this.imovel.id),
        imobiliaria: this.$utils.valOrNull(this.imobiliaria),

        plano: this.$utils.valOrNull(this.plano.id),
        seguradora: this.$utils.valOrNull(this.seguradora.id),
        valor: this.$utils.valOrNull(this.valor),
        locador: this.$utils.valOrNull(this.locador.id),
        locatario: this.$utils.valOrNull(this.locatario.id),
      };

      if (!criar) {
        dados["_method"] = "PUT";
      }

      this.loading++;

      this.$api
        .post(
          "/capitalizacao" + (!criar ? "/" + this.$route.params.id : ""),
          dados
        )
        .then(function (response) {
          self.$toast.add({
            severity: response.data.success ? "success" : "error",
            summary: response.data.success ? "Sucesso!" : "ERRO!",
            detail: response.data.message,
            life: response.data.success ? 3000 : 5000,
          });

          self.loading--;

          self.$router.push({ path: `/capitalizacoes` });
        })
        .catch(function (erro) {
          if (typeof erro.response != "undefined") {
            const response = erro.response;

            if (typeof response.data.fields != "undefined") {
              const dicionario = {};

              for (const f of response.data.fields) {
                if (typeof dicionario[f] != "undefined") {
                  self.fieldsError.push(dicionario[f]);
                } else {
                  self.fieldsError.push(f);
                }
              }
            }

            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: response.data.message,
              life: 5000,
            });

            self.loading--;
          }
        });
    },
  },
  components: {
    PessoasSimplificadoEdit,
    ImoveisEdit,
  },
};
</script>

<style scoped>
.button-seguradora {
  max-width: 100% !important;
  width: 250px !important;
  padding: 0 !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.button-seguradora.selecionada {
  border: 5px solid #f47920 !important;
  position: relative;
}

.button-seguradora.selecionada::after {
  font-family: primeicons;
  font-size: 20px;
  font-weight: 900;
  color: #f47920;
  content: "\e909";
  position: absolute;
  top: 5px;
  right: 8px;
  -webkit-font-smoothing: antialiased;
  --webkit-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

:deep(.p-selectbutton .p-button.p-highlight) {
  background: #6d6c6c !important;
  border-color: #666666 !important;
}

.selecao-detalhes .p-field {
  border: 1px solid #ccc;
  margin: 0;
  padding: 0.3rem 0.4rem;
  margin-top: -1px;
}

.selecao-detalhes .p-field span {
  display: block;
}

.selecao-detalhes .p-field label {
  font-weight: 500;
  margin-bottom: 4px;
}

.selecao-detalhes .p-grid {
  margin: 0;
}

:deep(.selecao-detalhes) th,
:deep(.selecao-detalhes) td {
  background: none !important;
  padding: 0.5rem !important;
}
</style>