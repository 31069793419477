<template>

  <BlockUI :blocked="loading>0">

    <div class="p-grid">

      <div class="p-col-12 p-md-6">
        <h3><i class="pi pi-user-edit" style="font-size: 1.6rem"></i> Cadastro Simplificado de Pessoa <span v-if="tipo == 'PF'">Física</span><span v-else>Jurídica</span></h3>
      </div>

      <div class="p-col-12 p-md-6">
        <Button
          id="buttonGravar"
          label="Gravar"
          icon="pi pi-check"
          style="float: right"
          class="p-mr-2 p-mb-3 p-button-lg p-button-success"
          @click="criar_alterar"
          v-if="$auth.userCan('PESSOAS_CADASTRAR')"
        ></Button>
      </div>

    </div>

    <div class="p-grid p-pt-3">

      <div class="p-md-6">

        <!-- Informações Básicas -->
        <h5><i class="pi pi-id-card"></i> Identificação</h5>
        
        <div class="card p-fluid">
          <div class="p-grid">

            <div class="p-field p-md-3" v-if="tipo == 'PF'">
              <label for="cpf">CPF</label>
              <InputMask
                id="cpf"
                mask="999.999.999-99"
                v-model="cpf"
                @change="validaCadastrado"
                @blur="validaCPF"
                @focus="cpfInvalido = false"
                :class="(fieldsError.some(v => v === 'cpf'))?'p-invalid':''"
              />
              <InlineMessage v-if="cpfInvalido">CPF Inválido</InlineMessage>
            </div>

            <div class="p-field p-md-5" v-else>
              <label for="cpf">CNPJ</label>
              <InputMask
                id="cpf"
                mask="99.999.999/9999-99"
                v-model="cpf"
                @blur="validaCNPJ"
                @focus="cnpjInvalido = false"
                :class="(fieldsError.some(v => v === 'cpf'))?'p-invalid':''"
              />
              <InlineMessage v-if="cnpjInvalido">CNPJ Inválido</InlineMessage>
            </div>

            <div :class="(tipo == 'PF')?'p-field p-md-9':'p-field p-md-12'">
              <label for="nome">
                <span v-if="tipo == 'PF'">Nome Completo</span>
                <span v-else>Razão Social</span>
              </label>
              <InputText
                id="nome"
                type="text"
                v-model="nome"
                maxlength="200"
                :class="(fieldsError.some(v => v === 'nome'))?'p-invalid':''"
              />
            </div>

            <div class="p-field p-md-3"  v-if="tipo == 'PF'">
              <label for="rg">RG</label>
              <InputText id="rg" type="text" v-model="rg" maxlength="20" 
              :class="(fieldsError.some(v => v === 'rg'))?'p-invalid':''" />
            </div>

            <div class="p-field p-md-3" v-if="tipo == 'PF'">
              <label for="rg_orgao">Órgão Emissor</label>
              <InputText
                id="rg_orgao"
                type="text"
                v-model="rg_orgao"
                :class="(fieldsError.some(v => v === 'rg_orgao'))?'p-invalid':''"
                maxlength="10"
              />
            </div>

            <div class="p-field p-md-3" v-if="tipo == 'PF'">
              <label for="rg_expedicao">Expedição</label>
              <InputMask
                id="rg_expedicao"
                v-model="rg_expedicao"
                mask="99/99/9999"
                slotChar="dd/mm/aaaa"
                :class="(fieldsError.some(v => v === 'rg_expedicao'))?'p-invalid':''"
              />
            </div>

            <div class="p-field p-md-3">
              <label for="nascimento">
                <span v-if="tipo == 'PF'">Nascimento</span>
                <span v-else>Fundação</span>
              </label>
              <InputMask
                v-model="nascimento"
                :class="(fieldsError.some(v => v === 'nascimento'))?'p-invalid':''"
                mask="99/99/9999"
                slotChar="dd/mm/aaaa"
              />
            </div>

            <div :class="(tipo == 'PF')?'p-field p-col-12':'p-field p-col-9'">
              <label for="email">E-Mail</label>
              <InputText
                id="email"
                type="text"
                v-model="email"
                :class="(fieldsError.some(v => v === 'email'))?'p-invalid':''"
                maxlength="200"
              />
            </div>

            <div class="p-field p-md-6" v-if="tipo == 'PF'">
              <label for="estado_civil">Estado Civil</label>
              <Dropdown
                id="estado_civil"
                v-model="estado_civil" :class="(fieldsError.some(v => v === 'estado_civil'))?'p-invalid':''"
                :options="estados_civis"
                optionLabel="nome"
                optionValue="id"
                placeholder="Selecione"
              />
            </div>

            <div class="p-field p-md-6" v-if="tipo == 'PF'">
              <label for="sexo">Sexo</label>
              <Dropdown
                id="sexo"
                v-model="sexo" :class="(fieldsError.some(v => v === 'sexo'))?'p-invalid':''"
                :options="[{id:'MASCULINO', nome: 'MASCULINO'},{id:'FEMININO', nome: 'FEMININO'}]"
                optionLabel="nome"
                optionValue="id"
                placeholder="Selecione"
              />
            </div>


          </div>
        </div>

      </div>

      <div class="p-md-6" v-if="tipo == 'PJ'">

        <!-- Endereço -->
        <h5><i class="pi pi-map-marker"></i> Endereço da Empresa</h5>

        <div class="card p-fluid">
          <div class="p-grid">
            <div class="p-field p-md-3">
              <label for="residencia_cep">CEP</label>
              <InputMask
                id="residencia_cep"
                type="text"
                v-model="residencia_cep"
                :class="(fieldsError.some(v => v === 'residencia_cep'))?'p-invalid':''"
                mask="99.999-999"
                :unmask="true"
                @change="validaCEP"
                @focus="cepInvalido = false"
              />
              <InlineMessage v-if="cepInvalido">CEP Inválido</InlineMessage>
            </div>

            <div class="p-field p-md-9">
              <label for="residencia_logradouro">Endereço</label>
              <InputText
                id="residencia_logradouro"
                type="text"
                v-model="residencia_logradouro"
                :class="(fieldsError.some(v => v === 'residencia_logradouro'))?'p-invalid':''"
                maxlength="300"
              />
            </div>

            <div class="p-field p-md-2">
              <label for="residencia_numero">Número</label>
              <InputText
                id="residencia_numero"
                type="text"
                v-model="residencia_numero" :class="(fieldsError.some(v => v === 'residencia_numero'))?'p-invalid':''"
                maxlength="10"
              />
            </div>

            <div class="p-field p-md-10">
              <label for="residencia_complemento">Complemento</label>
              <InputText
                id="residencia_complemento"
                type="text"
                v-model="residencia_complemento" :class="(fieldsError.some(v => v === 'residencia_complemento'))?'p-invalid':''"
                maxlength="150"
              />
            </div>

            <div class="p-field p-md-5">
              <label for="residencia_bairro">Bairro</label>
              <InputText
                id="residencia_bairro"
                type="text"
                v-model="residencia_bairro" :class="(fieldsError.some(v => v === 'residencia_bairro'))?'p-invalid':''"
                maxlength="150"
              />
            </div>

            <div class="p-field p-md-5">
              <label for="residencia_cidade">Cidade</label>
              <InputText
                id="residencia_cidade"
                type="text"
                v-model="residencia_cidade" :class="(fieldsError.some(v => v === 'residencia_cidade'))?'p-invalid':''"
                maxlength="100"
              />
            </div>

            <div class="p-field p-md-2">
              <label for="residencia_uf">UF</label>
              <InputText
                id="residencia_uf"
                type="text"
                v-model="residencia_uf" :class="(fieldsError.some(v => v === 'residencia_uf'))?'p-invalid':''"
                maxlength="20"
              />
            </div>
          </div>

        </div>

      </div>

      <div class="p-md-6">

        <!-- Telefones -->
        <h5 :style="(fieldsError.some(v => v === 'telefones'))?'color: red':''"><i class="pi pi-phone"></i> Telefones</h5>

        <div class="card p-fluid">
          <div class="p-grid">

            <div class="p-field p-md-6">
              <label for="telefone">Número de Telefone</label>
              <InputMask
                id="telefone"
                type="text"
                v-model="telefone"
                :class="(fieldsError.some(v => v === 'telefone'))?'p-invalid':''"
                mask="(99) 9999-9999?9"
                @focus="telefoneInvalido = false"
              />
              <InlineMessage v-if="telefoneInvalido">
                O Telefone é inválido
              </InlineMessage>
            </div>

            <div class="p-field p-md-6">
              <label for="telefone_nome">Observações (opcional)</label>
              <InputText
                id="telefone_nome"
                type="text"
                v-model="telefone_nome"
                :class="(fieldsError.some(v => v === 'telefone_nome'))?'p-invalid':''"
                maxlength="150"
              />
            </div>

            <div class="p-field p-md-6">
              <div class="p-field-checkbox">
                <Checkbox
                  id="e_whatsapp"
                  v-model="telefone_e_whatsapp"
                  :binary="true"
                />
                <label for="e_whatsapp">O telefone é WhatsApp.</label>
              </div>
            </div>

            <div class="p-field p-md-6">
              <Button
                label="Adicionar Telefone"
                @click="adicionarTelefone"
              ></Button>
            </div>

            <div class="p-field" v-if="telefones.length">
              <DataTable sortMode="single" :value="telefones">
                <Column field="nome" headerClass="colNome" header="Observações">
                  <template #body="slotProps">
                    {{
                      slotProps.data.nome
                        ? slotProps.data.nome
                        : "Nenhuma"
                    }}
                  </template>
                </Column>
                <Column
                  field="telefone"
                  headerClass="colTelefone"
                  headerStyle="width:145px"
                  header="Telefone"
                >
                  <template #body="slotProps">
                    {{ $utils.formatPhone(slotProps.data.telefone) }}
                  </template>
                </Column>
                <Column
                  field="tipo"
                  headerClass="colTipo"
                  headerStyle="width:67px"
                  header="Tipo"
                ></Column>
                <Column
                  field="e_whatsapp"
                  headerClass="colWhatsapp"
                  headerStyle="width:65px"
                  header="Whatsapp?"
                >
                  <template #body="slotProps">
                    {{ slotProps.data.e_whatsapp ? "Sim" : "Não" }}
                  </template>
                </Column>
                <Column headerStyle="width:52px">
                  <template #body="slotProps">
                    <Button
                      icon="pi pi-trash"
                      class="p-mr-2 p-mb-2 p-button-danger"
                      @click="removerTelefone($event, slotProps.index)"
                    ></Button>
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>

      <div class="p-md-6" v-if="tipo == 'PJ'">

        <!-- Sócios -->
        <h5 :style="(fieldsError.some(v => v === 'socios'))?'color: red':''"><i class="pi pi-users"></i> Sócios Representantes</h5>

        <div class="card p-fluid">
          <div class="p-grid">

            <div class="p-field p-md-4">
              <label for="socio_cpf">CPF</label>
              <InputMask
                id="socio_cpf"
                type="text"
                v-model="socio_cpf"
                :class="(fieldsError.some(v => v === 'socio_cpf'))?'p-invalid':''"
                mask="999.999.999-99"
                @blur="validaSocioCPF"
                @focus="socioCpfInvalido = false"
              />
              <InlineMessage v-if="socioCpfInvalido">
                CPF Inválido
              </InlineMessage>
            </div>

            <div class="p-field p-md-8">
              <label for="socio_nome">Nome Completo</label>
              <InputText
                id="socio_nome"
                type="text"
                v-model="socio_nome"
                :class="(fieldsError.some(v => v === 'socio_nome'))?'p-invalid':''"
                maxlength="150"
              />
            </div>

            <div class="p-field p-md-12">
              <Button
                label="Adicionar Sócio"
                @click="adicionarSocio"
              ></Button>
            </div>

            <div class="p-field" v-if="socios.length">
              <DataTable sortMode="single" :value="socios">
                <Column field="cpf" headerClass="colCPF" header="CPF"></Column>
                <Column field="nome" headerClass="colNome" header="Nome" headerStyle="width: 100%"></Column>
                <Column headerStyle="width:52px">
                  <template #body="slotProps">
                    <Button
                      icon="pi pi-trash"
                      class="p-mr-2 p-mb-2 p-button-danger"
                      @click="removerSocio($event, slotProps.index)"
                    ></Button>
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>

      </div>
      
    </div>

    <div class="center-loading" v-if="loading>0">
      <ProgressSpinner />
    </div>

  </BlockUI>

  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />
</template>

<script>

// TODO COLOCAR COMPORTAMENTOS AUTOMATIZADOS DE VALIDAR CPF E CEP AO ALTERAR NO CAMPO EM CONFORMIDADE COM O RESTANTE DO SISTEMA
// XXX VERIFICAR TODO DESTE ARQUIVO

import axios from "axios";

export default {
  props: {
    pessoa: Object,
    imobiliaria: Number,
    tipo: {
			type: String,
			default: "PF"
		}
  },
  emits: [
    "save",
    "created",
    "updated"
  ],
  data() {
    return {
      // 1. Controle de apresentação
      loading: 2,

      // 1.1. Validadores
      telefoneInvalido: false,
      cepInvalido: false,
      cpfInvalido: false,
      socioCpfInvalido: false,
      cnpjInvalido: false,
      fieldsError: [],

      // 2. Chaves Estrangeiras
      estados_civis: [],

      // 3. Dados de formulários

      // 3.1. Informações Gerais

      cpf: "",
      nome: "",
      rg: "",
      rg_orgao: "",
      rg_expedicao: "",
      nascimento: "",
      email: "",

      telefone_nome: "",
      telefone_e_whatsapp: false,
      telefone: "",

      telefones: [],

      socio_nome: "",
      socio_cpf: "",

      socios: [],

      residencia_cep: "",
      residencia_logradouro: "",
      residencia_numero: "",
      residencia_complemento: "",
      residencia_bairro: "",
      residencia_cidade: "",
      residencia_uf: "",

      estado_civil: "",
      sexo: "",

      // 4. Dado selecionado para edição

      pessoa_edit: null,
      imobiliaria_id: null,
      
    };
  },

  mounted() {
    const self = this;

    if(typeof this.pessoa != "object" || this.pessoa == null) {

      if(typeof this.imobiliaria != "number") {
        if(this.$auth.currentUser.imobiliaria == null)
          alert('Imobiliária não identificada!');
        else
          this.imobiliaria_id = this.$auth.currentUser.imobiliaria;
      } else {
        this.imobiliaria_id = this.imobiliaria;  
      }
    } else {
      this.imobiliaria_id = this.pessoa.imobiliaria;
    }
    
    this.$api.get("/pessoas-estados-civis").then(function (response) {
      const dados = response.data;

      if (dados.success) {
        self.estados_civis = dados.data;
      }

      self.loading--;

    });

    this.carregarObjetoRota();
  },

  watch: {

    pessoa_edit: function (val) {
      
      this.limparFormulario();

      if (val != null) {

        // 3. Dados de formulários

        // 3.1. Informações Gerais

        this.cpf = val.cpfcnpj;
        this.nome = val.nome;
        this.rg = val.rg;
        this.rg_orgao = val.rg_orgao;
        this.rg_expedicao = this.$utils.formatDateToBr(val.rg_expedicao);
        this.nascimento = this.$utils.formatDateToBr(val.nascimento);
        this.email = val.email;

        if(Array.isArray(val.contatos)) {
          for(const c of val.contatos) {
            this.telefones.push({
              nome: c.nome,
              telefone: c.telefone,
              tipo: c.tipo,
              e_whatsapp: c.e_whatsapp,
            });
          }
        }

        if(Array.isArray(val.socios)) {
          for(const c of val.socios) {
            this.socios.push({
              nome: c.socio_item.nome,
              cpf: c.socio_item.cpf,
            });
          }
        }

        this.residencia_cep = val.cep;
        this.residencia_logradouro = val.logradouro;
        this.residencia_numero = val.numero;
        this.residencia_complemento = val.complemento;
        this.residencia_bairro = val.bairro;
        this.residencia_cidade = val.cidade;
        this.residencia_uf = val.uf;

        this.estado_civil = val.estado_civil;
        this.sexo = val.sexo;

      }
    },

  },

  methods: {

    // // // Métodos padrões para este modelo de tela // // //

    carregarObjetoRota(id = 0) {
      const self = this;

      let pessoa = null;
      if(id != 0) {
        pessoa = {
          "id": id
        };
      } else if(typeof this.pessoa != "undefined") {
        pessoa = this.pessoa;
      }

      if (
        (typeof this.$route.params.id != "string" ||
        this.$route.params.id == "") && pessoa == null
      ) {
        self.loading--;
        return;
      }

      let bloqueio = setInterval(() => {

        if(self.loading == 1) {

          if(pessoa != null && Object.keys(pessoa).length > 5) {
            self.pessoa_edit = self.$utils.getStdObject(pessoa);
            self.loading--;
            clearInterval(bloqueio);
          } else {

            let pessoaId;
            if(pessoa != null)
              pessoaId = pessoa.id;
            else
              pessoaId = this.$route.params.id;

            self.$api
              .get("/pessoas/" + pessoaId)
              .then(function (response) {
                const dados = response.data;

                if (dados.success) { 
                  self.pessoa_edit = dados.data;
                  self.loading = false;
                } else {
                  alert(dados.message);
                  self.$router.push({ path: `/pessoas` });
                }

                self.loading--;

                clearInterval(bloqueio);

              });
          }

        }

      },500);

    },

    limparFormulario() {
      //Limpa as variáveis

      // 1.1. Validadores
      this.telefoneInvalido = false;
      this.cepInvalido = false;
      this.cpfInvalido = false;

      // 3. Dados de formulários

      // 3.1. Informações Gerais

      this.cpf = "";
      this.nome = "";
      this.rg = "";
      this.rg_orgao = "";
      this.rg_expedicao = "";
      this.nascimento = "";
      this.email = "";

      this.telefone_nome = "";
      this.telefone_e_whatsapp = false;
      this.telefone = "";

      this.telefones = [];

      this.socio_nome = "";
      this.socio_cpf = "";

      this.socios = [];

      this.residencia_cep = "";
      this.residencia_logradouro = "";
      this.residencia_numero = "";
      this.residencia_complemento = "";
      this.residencia_bairro = "";
      this.residencia_cidade = "";
      this.residencia_uf = "";

      this.estado_civil = "";
      this.sexo = "";

    },

    // // // AÇÕES DO USUÁRIO // // //

    async validaCadastrado() {

      try {
        const response = await this.$api
          .get("/pessoas?fields=id&filters={%22imobiliaria%22:[%22" + this.imobiliaria + "%22],%22str%22:[%22" + this.cpf.replace(/[^0-9]/g, "") + "%22]}");

        const dados = response.data;

        if (dados.success && dados.data.length > 0) { 
          this.loading = 1;
          this.carregarObjetoRota(dados.data[0].id);
        } else {

          if(!this.cpfInvalido) {

            this.loading++;

            const response = await this.$api
              .get("/pessoas/apicpfcnpj/" + this.cpf.replace(/[^0-9]/g, ""));

            const dados = response.data;

            if (dados.success) { 
              
              this.nome = dados.data.nome;
              this.nascimento = dados.data.nascimento;

            }

            this.loading--;

          }

        }

      } catch (error) {
        console.log("Falha ao verificar pessoa já cadastrada!");
        console.log(error);
      }

    },

    validaCEP() {

      const self = this;

      axios
        .get("https://brasilapi.com.br/api/cep/v1/" + this.residencia_cep)
        .then(function (response) {
          //Sucesso
          if (!self.residencia_uf) self.residencia_uf = response.data.state;
          if (!self.residencia_cidade) self.residencia_cidade = response.data.city;
          if (!self.residencia_bairro) self.residencia_bairro = response.data.neighborhood;
          if (!self.residencia_logradouro) self.residencia_logradouro = response.data.street;
        })
        .catch(function (error) {
          //Erro
          if (error.response.data.name == "CepPromiseError")
            self.cepInvalido = true;
        });
    },

    validaCPF() {
      this.cpfInvalido = false;
      if (this.$utils.isCPF(this.cpf)) {
        this.cpfInvalido = false;
      } else {
        this.cpfInvalido = true;
      }
    },

    validaSocioCPF() {
      this.socioCpfInvalido = false;
      if (this.$utils.isCPF(this.socio_cpf)) {
        this.socioCpfInvalido = false;
      } else {
        this.socioCpfInvalido = true;
      }
    },

    validaCNPJ() {
      this.cnpjInvalido = false;
      if (this.$utils.isCNPJ(this.cnpj)) {
        this.cnpjInvalido = false;
      } else {
        this.cnpjInvalido = true;
      }
    },

    adicionarTelefone() {
      if (this.telefone.length == 15 || this.telefone.length == 14) {
        this.telefoneInvalido = false;

        this.telefones.push({
          nome: this.telefone_nome,
          telefone: this.telefone.replace(/[^0-9]/g, ""),
          tipo: this.telefone.substr(5, 1) == "9" ? "Móvel" : "Fixo",
          e_whatsapp: (this.telefone_e_whatsapp)?1:0,
        });
      } else {
        this.telefoneInvalido = true;
      }
    },

    removerTelefone(event, index) {
      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: "Tem certeza que deseja excluir?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          self.telefones.splice(index, 1);
        },
      });
    },

    adicionarSocio() {
      if (this.$utils.isCPF(this.socio_cpf)) {
        this.socioCpfInvalido = false;
        this.socios.push({
          nome: this.socio_nome,
          cpf: this.socio_cpf
        });
      } else {
        this.socioCpfInvalido = true;
      }
    },

    removerSocio(event, index) {
      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: "Tem certeza que deseja excluir?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          self.socios.splice(index, 1);
        },
      });
    },

    validaForm() {

      this.fieldsError = [];

      let campos;
      if(this.tipo == 'PF') {
        campos = [
          "cpf","nome","rg","rg_orgao","rg_expedicao","nascimento","email","telefones",
          "estado_civil","sexo"
        ];
      } else {
        campos = [
          "cpf","nome","nascimento","email","telefones","socios","residencia_cep",
          "residencia_logradouro","residencia_numero","residencia_bairro","residencia_cidade","residencia_uf",
        ];
      }

      for(const c of campos) {

        if(typeof this[c] == "undefined" || this[c] === null || this[c] == 0 || this[c] === "" || this[c] === "0") {
          this.fieldsError.push(c);
        } else if(Array.isArray(this[c]) && this[c].length == 0) {
          this.fieldsError.push(c);
        }

      }

      if(this.fieldsError.length > 0) {

        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "Preencha corretamente todas as informações!",
          life: 5000,
        });

        return false;
      }

      return true;

    },

    criar_alterar() {

      if(!this.validaForm())
        return;

      const self = this;
      
      const criar = (
        (typeof this.$route.params.id != "string" ||
        this.$route.params.id == "") && this.pessoa == null
      );

      if (this.telefones.length == 0 && this.telefone.length != 0) {
        this.adicionarTelefone();
      }

      if (this.socios.length == 0 && this.socio_nome.length != 0 && this.socio_cpf.length != 0) {
        this.adicionarSocio();
      }

      let dados = {
        "tipo": this.tipo,
        "nome": this.nome,
        "cpfcnpj": this.cpf.replace(/[^0-9]/g, ""),
        "rg": this.rg,
        "rg_orgao": this.rg_orgao,
        "rg_expedicao": this.$utils.formatDateToMysql(this.rg_expedicao),
        "nascimento": this.$utils.formatDateToMysql(this.nascimento),
        "email": this.email,

        "cep": this.residencia_cep.replace(/[^0-9]/g, ""),
        "logradouro": this.residencia_logradouro,
        "numero": this.residencia_numero,
        "complemento": this.residencia_complemento,
        "bairro": this.residencia_bairro,
        "cidade": this.residencia_cidade,
        "uf": this.residencia_uf,
        
        "estado_civil": this.estado_civil,
        "sexo": this.sexo,

        "imobiliaria": this.imobiliaria_id,

        "contatos": this.telefones,
        "socios": this.socios,

      };

      dados = Object.assign(this.$utils.getStdObject(this.pessoa), this.$utils.getStdObject(dados));

      let pessoa_id;
      if (!criar) {
        dados["_method"] = "PUT";

        if(this.pessoa)
          pessoa_id = this.pessoa.id;
        else
          pessoa_id = this.$route.params.id;

      }

      this.$api
        .post(
          "/pessoas" + (!criar ? "/" + pessoa_id : ""),
          dados
        )
        .then(function (response) {

          self.$toast.add({
            severity: (response.data.success)?"success":"error",
            summary: (response.data.success)?"Sucesso!":"ERRO!",
            detail: response.data.message,
            life: (response.data.success)?3000:5000,
          });

          self.$emit("save", response.data.data);

          if(criar) {
            self.$emit("created", response.data.data);
          } else {
            self.$emit("updated", response.data.data);
          }

        })
        .catch(function (erro) {

          if (typeof erro.response != "undefined") {

            const response = erro.response;

            if(typeof response.data.fields != "undefined") {

              const dicionario = {
                "logradouro": "residencia_logradouro",
                "numero": "residencia_numero",
                "complemento": "residencia_complemento",
                "bairro": "residencia_bairro",
                "cidade": "residencia_cidade",
                "uf": "residencia_uf",
                "cep": "residencia_cep",
              };

              for(const f of response.data.fields) {

                if(typeof dicionario[f] != "undefined") {
                  self.fieldsError.push(dicionario[f]);
                } else {
                  self.fieldsError.push(f);
                }

              }

            }

            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: response.data.message,
              life: 5000,
            });

          }

        });
    }

  },
};
</script>

<style scoped>
</style>